import { render, staticRenderFns } from "./agreement-tpl.vue?vue&type=template&id=4969e82d&scoped=true"
import script from "./agreement-tpl.vue?vue&type=script&lang=js"
export * from "./agreement-tpl.vue?vue&type=script&lang=js"
import style0 from "./agreement-tpl.vue?vue&type=style&index=0&id=4969e82d&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4969e82d",
  null
  
)

export default component.exports