/*
 * @Description: 项目配置数据
 */

// 固定页面集合  这个是选择页面跳转的时候出现的 选择的微页面读出的配置
export const fixedPages = [{
		id: 'search',
		name: '搜索页',
		type: 'fixed'
	},
	{
		id: 'car',
		name: '购物车(一级)',
		type: 'fixed'
	},
	{
		id: 'my',
		name: '我的(一级)',
		type: 'fixed'
	},
	{
		id: 'category',
		name: '商品分类(一级)',
		type: 'fixed'
	},
	{
		id: 'horseH5',
		name: '客多赞马术',
		type: 'fixed'
	},
	{
		id: 'horseH5Tab',
		name: '客多赞马术(一级)',
		type: 'fixed'
	},
	{
		id: 'mallH5',
		name: '客多赞商城',
		type: 'fixed'
	},
	{
		id: 'net',
		name: '人脉关系(一级)',
		type: 'fixed'
	},	
	{
		id: 'agreement',
		name: '预约须知(一级)',
		type: 'fixed'
	},
	{
		id: 'modeSelect',
		name: '预约模式(禁用)',
		type: 'fixed'
	},
	{
		id: 'shopInfo',
		name: '店铺详情',
		type: 'fixed'
	},
	{
		id: 'rechange',
		name: '会员充值',
		type: 'fixed'
	},
]

// 导航配置  这个不知道是干什么用的,暂时不要乱加,加了首页会打不开
export const navigation = {
	label: '导航',
	styles: {
		background: '#fff'
	},
	list: [{
			id: '00001',
			icon: 'icon-shop',
			text: '首页',
			jump: {
				type: 'custom',
				id: '000000'
			}
		},
		{
			id: '00003',
			icon: 'icon-sort',
			text: '分类',
			jump: {
				type: 'fixed',
				id: 'category'
			}
		},
		{
			id: '00004',
			icon: 'icon-cart',
			text: '购物车',
			jump: {
				type: 'fixed',
				id: 'car'
			}
		},
		{
			id: '00005',
			icon: 'icon-my',
			text: '我的',
			jump: {
				type: 'fixed',
				id: 'my'
			}
		},
		{
			id: '00006',
			icon: 'icon-my',
			text: '人脉',
			jump: {
				type: 'fixed',
				id: 'net'
			}
		}	
		
		
	]
}

// 商品分组配置
export const goodsGroups = [{
		name: '新品上架',
		type: '0001',
		level: 1,
		child: []
	},
	{
		name: '热销排行',
		type: '0002',
		level: 1,
		child: []
	}
]

// 商品列表模板配置
export const listTpl = {
	model: 'one',
	addIcon: 'cart'
}

// 商品列表模板配置
export const categoryTpl = {
	advertising: false // 广告位
}

// 商城初始配置   初始图片配置
export const rojectModel = {
	name: '',
	cover: 'https://kdz-2023-photo-001.oss-cn-shanghai.aliyuncs.com/upload/202403/06/682206-202403061358362465.png',
	config: {
		navigation: navigation,
		goodsGroups: goodsGroups,
		listTpl: listTpl,
		categoryTpl: categoryTpl,
		hotList: [],
		backgroundColor: '#FFFFFF'
	},
	pages: [
		// 自定义页面集合
		{
			id: '000000',
			name: '首页',
			home: true,
			componentList: []
		}
	]
}