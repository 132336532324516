<!--
 * @Description: 标题组件对应的配置页
-->
<template>
	<div class="flex p10 bg-white">
		<!-- 	组件属性:  --- 组件属性设置 -->
		<div class="w70 f13 f-grey pt10 ">{{label}}:</div>
		<div class="flex-1">
			<slot></slot>
		</div>
	</div>
</template>

<script>
	export default {
		name: "ConfigItem",
		props: ["label", "info"],
		data() {
			return {};
		},
	};
</script>